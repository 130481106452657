import { Coupon, MCustomer, OtherInfoAnswer, Stamp } from '@/store/models/db/log-models';

/** アプリケーションで用いるモデル **/
export type CustomerModel = MCustomer;

export class Customer {
  constructor(public params: CustomerModel) {}

  /** Getter */
  get lineUserId(): string {
    return this.params.lineUserId;
  }

  get displayName(): string {
    return this.params.displayName;
  }

  get pictureUrl(): string {
    return this.params.pictureUrl;
  }

  get membershipNo(): string {
    return this.params.membershipNo ?? '';
  }

  get fullName(): string {
    return `${this.lastName} ${this.firstName}`;
  }

  get firstName(): string {
    return this.params.firstName ?? '';
  }

  get lastName(): string {
    return this.params.lastName ?? '';
  }

  get firstNameKana(): string {
    return this.params.firstNameKana ?? '';
  }

  get lastNameKana(): string {
    return this.params.lastNameKana ?? '';
  }

  get tel(): string {
    return this.params.tel ?? '';
  }

  get sex(): string {
    return this.params.sex ?? '';
  }

  get birthday(): Date | null {
    return this.params.birthday ?? null;
  }

  get postCode(): string {
    return this.params.postCode ?? '';
  }

  get address(): string {
    return this.params.address ?? '';
  }

  get email(): string {
    return this.params.email ?? '';
  }

  get otherInfo(): OtherInfoAnswer[] {
    return this.params.otherInfo ?? [];
  }

  get point(): number {
    return this.params.point ?? 0;
  }

  get nextBooking(): Date | null {
    return this.params.nextBooking ?? null;
  }

  get answerdQuestionnaireIds(): string[] {
    return this.params.answerdQuestionnaireIds ?? [];
  }

  get stampCard(): Stamp[] {
    return this.params.stampCard ?? [];
  }

  get coupons(): Coupon[] {
    return this.params.coupons ?? [];
  }

  get lastLoginAt(): Date | null {
    return this.params.lastLoginAt ?? null;
  }

  get isCustomer(): boolean {
    return Boolean(this.membershipNo);
  }
}
